<template>
  <router-view />
</template>



<style lang="scss">
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #000;
  text-decoration: none;
  list-style: none;
}
</style>
