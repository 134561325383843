<template>
  <div class="imageFond"></div>
  <main>
    <h1>THREEMAN</h1>
    <div class="indication">
      <div class="indication-content">Bienvenue !</div>
    </div>
    <router-link @click="effacerCache()" to="./game" class="btn dice--roll"
      >Démarrer une nouvelle partie</router-link
    >
    <router-link v-if="this.lsP" to="./game" class="btn dice--roll"
      >Reprendre la partie</router-link
    >
    <p>"L'alcool c'est pas cool"</p>
  </main>
</template>

<script>
export default {
  data() {
    return {
      lsP: false,
    };
  },
  methods: {
    effacerCache() {
      localStorage.removeItem("ls");
    },
  },
  mounted() {
    if (localStorage.ls) {
      this.lsP = true;
    }
  },
};
</script>

<style lang="scss" src="./HomeView.scss" scoped></style>
