<template>
  <div class="imageFond"></div>
  <main>
    <h1>THREEMAN</h1>
    <section class="dice--container">
      <img
        :src="require(`@/assets/dice-${dice1}.png`)"
        alt=""
        @click="lancerDesIntermediaire(1)"
        v-if="!autreDes"
        class="dice--1"
      />
      <img
        :src="require(`@/assets/dice-${dice2}.png`)"
        v-if="!autreDes"
        @click="lancerDesIntermediaire(2)"
        alt=""
        class="dice--2"
      />
      <img
        @click="lancerDesIntermediaire(3)"
        v-if="autreDes"
        :src="require(`@/assets/dice-${diceExtra}.png`)"
        alt=""
        class="dice--seul"
      />
    </section>
    <button @click="lancerDesIntermediaire()" class="btn dice--roll">
      Lancer les des
    </button>
    <section class="message">
      <div class="tour">Manche {{ ls.manche }}</div>
      <div class="joueur">
        Au tour de <br />
        <span class="epais">
          {{ ls.listeJoueurs[ls.currentPlayer] }}
        </span>
      </div>
      <div @click="essaiJS" class="indication">
        <div class="indication-content">
          {{ messages[0] }}
        </div>
        <div v-if="messages[1]" class="indication-content petit">
          {{ messages[1] }}
        </div>
      </div>
      <div class="regle">
        <ul>
          <li v-for="regle in ls.regles" :key="regle">{{ regle }}</li>
        </ul>
      </div>
      <div v-if="ls.statut !== 'recherche'" class="cible">
        {{ threeman }}
        {{ ls.nbthreeman === 1 ? "est le" : "sont les" }} Threeman
      </div>
    </section>
    <section class="buttons">
      <button class="btn nvPartie" @click="nouvellePartie()">
        Nouvelle Partie
      </button>
      <button class="btn nvPartie" @click="afficherlisteJoueurs()">
        Joueurs
      </button>
      <button class="btn nvPartie" @click="ouvrirStat()">Consommations</button>
    </section>
  </main>

  <div class="listeDesJoueurs" v-if="displayPlayerList">
    <label class="nbplayer nbplayer-label">Nombre de joueurs </label>
    <input class="nbplayer" v-model="nbPlayer" type="number" min="3" />
    <button
      class="btn nvPartie"
      v-if="accederAuJeuToggle"
      @click="accederAuJeu()"
    >
      Jouer
    </button>
    <hr />
    <div class="player-container">
      <div v-for="n in nbPlayer" :key="n" class="namePlayer">
        <label>Nom du joueur {{ n }}</label>
        <input
          type="text"
          autocomplete="new-password"
          v-model="ls.listeJoueurs[n - 1]"
        />
      </div>
    </div>
  </div>

  <div v-if="alerte" @click="fermerAlerte()" class="alerte">
    <div v-if="desAlerte" class="desAlerte">
      <img
        :src="require(`@/assets/dice-${dice1}.png`)"
        alt=""
        v-if="!autreDes"
        class="dice--1"
      />
      <img
        :src="require(`@/assets/dice-${dice2}.png`)"
        v-if="!autreDes"
        alt=""
        class="dice--2"
      />
    </div>
    <div class="message-content">{{ messageAlerte }}</div>
  </div>

  <div class="statistique" @click="fermerStat()" v-if="statistique">
    <h1>Consommations</h1>
    <div class="tableauStat">
      <ul>
        <li class="c1">Nom joueur</li>
        <li class="c2">Manche en Threeman</li>
        <li class="c3">Gorgées</li>
        <li class="c4">Sec</li>
      </ul>
      <hr />
      <ul v-for="joueur in ls.listeJoueurs" :key="joueur">
        <li class="c1">{{ joueur }}</li>
        <li class="c2">{{ ls.statistique[joueur].nbTourThreeman }}</li>
        <li class="c3">{{ ls.statistique[joueur].nbGorgee }}</li>
        <li class="c4">{{ ls.statistique[joueur].nbSec }}</li>
      </ul>
    </div>
    <button class="btn" @click="fermerStat()">Jouer</button>
  </div>

  <div class="distribution" v-if="distribution">
    <h1>Gorgees à distribuer</h1>
    <h1>{{ nbGorgeeDistri }}</h1>
    <div class="tableauDistri">
      <ul v-for="(joueur, index) in ls.listeJoueurs" :key="joueur">
        <li @click="enleverGorgee(index)" class="modif">-</li>
        <li class="joueur">{{ joueur }} : {{ listeJoueursDistri[index] }}</li>
        <li @click="ajouterGorgee(index)" class="modif">+</li>
      </ul>
    </div>
    <button v-if="validerDistri" class="btn" @click="fermerDistri()">
      Valider
    </button>
  </div>

  <div class="choix" v-if="choix">
    <h1 v-html="choixMessage"></h1>
    <div class="bouton-container">
      <button
        class="joueur"
        v-for="(joueur, index) in ls.listeJoueurs"
        :key="joueur"
        @click="choixBoisson(index, this.nbGorgeeChoix)"
      >
        {{ joueur }}
      </button>
    </div>
  </div>

  <div class="regleAffiche" v-if="regleAffiche">
    <h1>REGLES</h1>
    <h2>Exemples de règle</h2>
    <div class="exregle-container">
      <button
        @click="reglePredefini(index)"
        v-for="(reglePredif, index) in reglesPredif"
        :key="index"
      >
        {{ reglePredif }}
      </button>
    </div>
    <h2>Définir sa propre règle</h2>
    <input v-model="regleRedac" type="text" />
    <h2>Règle déjà en cours</h2>

    <div class="container">
      <hr />
      <div
        class="regle--encours"
        :key="index"
        v-for="(regle, index) in ls.regles"
      >
        <div class="containerRegleEncours">
          <div class="content">{{ regle }}</div>
          <div
            v-if="ls.regles[index]"
            class="suppRegle"
            @click="suppRegle(index)"
          >
            X
          </div>
        </div>
        <div v-if="ls.regles[index]" class="horRow"></div>
      </div>
    </div>
    <button class="valider" v-if="regleRedac" @click="fermerRegle()">
      Valider
    </button>
  </div>
</template>

<script>
export default {
  name: "GameView",
  data() {
    return {
      accederAuJeuToggle: false,
      jeuDebute: false,
      regleAffiche: false,
      regleRedac: "",
      reglesPredif: [
        "Co-Threeman",
        "Bufalo",
        "Faire un compliment avant de boire",
        "S'appeler par les noms de famille",
        "Enlever le petit bonhomme avant de boire",
        "Gros mots interdits",
        "Ni oui Ni non",
        "Un rire une gorgée",
        "Regarder le threeman dans les yeux = 1 gorgée",
      ],
      choix: false,
      choixMessage: "",
      nbGorgeeChoix: 0,
      statistique: false,
      distribution: false,
      validerDistri: false,
      dice1: 3,
      dice2: 3,
      listeJoueursDistri: [],
      nbGorgeeDistri: 4,
      diceExtra: 6,
      messages: [
        "Bienvenue au jeu du THREEMAN",
        "Commençons par partir à sa recherche",
      ],
      ls: {
        statut: "",
        listeJoueurs: [],
        lastcombi: [],
        nbthreeman: 1,
        statistique: {},
        manche: 1,
        currentPlayer: 0,
        messages: [],
        regles: ["Aucune règle pour le moment"],
        positionThreeman: 0,
        doubleTreeman: false,
        positionCoThreeman: 0,
      },
      threeman: "",
      nextPlayer: 1,
      previousPlayer: 2,
      autreDes: false,
      displayPlayerList: false,
      nbPlayer: 3,
      alerte: false,
      desAlerte: false,
      messageAlerte: "",
      desPipe: [1, 3, 2],
    };
  },
  mounted() {
    console.log(this.regleAffiche);
    if (localStorage.ls) {
      this.ls = JSON.parse(localStorage.ls);
      for (let i = this.ls.listeJoueurs.length - 1; i >= 0; i--) {
        if (!this.ls.listeJoueurs[i]) {
          this.ls.listeJoueurs.splice(i, 1);
        }
      }
      this.nbPlayer =
        this.ls.listeJoueurs.length < 3 ? 3 : this.ls.listeJoueurs.length;
      this.dice1 = this.ls.lastcombi[0] ? this.ls.lastcombi[0] : 3;
      this.dice2 = this.ls.lastcombi[1] ? this.ls.lastcombi[1] : 3;
      this.nbthreeman = this.ls.nbthreeman;
      this.messages = this.ls.messages;
      this.currentPlayer = this.ls.currentPlayer;
      this.regles = this.ls.regles;
      this.positionThreeman = this.ls.positionThreeman;
    } else {
      this.displayPlayerList = true;
    }

    if (!this.ls.statut) {
      this.ls.statut = "recherche";
    }

    document.querySelector(".dice--1").style.transform = "rotate(0deg)";
    document.querySelector(".dice--2").style.transform = "rotate(0deg)";
  },
  watch: {
    ls: {
      handler(newls) {
        localStorage.setItem("ls", JSON.stringify(newls));

        for (let i = newls.listeJoueurs.length - 1; i >= 0; i--) {
          if (!newls.listeJoueurs[i]) {
            newls.listeJoueurs.splice(i, 1);
          }
        }

        if (newls.listeJoueurs.length >= 3) {
          this.accederAuJeuToggle = true;
        } else {
          this.accederAuJeuToggle = false;
        }
      },
      deep: true,
    },
    messages: {
      handler(newmes) {
        this.ls.messages = newmes;
      },
      deep: true,
    },
  },
  methods: {
    getRandomNumber(min, max) {
      let randomNumber = crypto.getRandomValues(new Uint32Array(1))[0];
      randomNumber = randomNumber / 4294967286;
      return Math.floor(randomNumber * (max - min + 1)) + min;
    },
    essaiJS() {},
    accederAuJeu() {
      this.displayPlayerList = false;
      if (!this.jeuDebute) {
        for (let i = 0; i < this.ls.listeJoueurs.length; i++) {
          let cle = this.ls.listeJoueurs[i];
          this.ls.statistique[cle] = {
            nbTourThreeman: 0,
            nbGorgee: 0,
            nbSec: 0,
          };
        }
      } else {
        for (let i = 0; i < this.ls.listeJoueurs.length; i++) {
          let cle = this.ls.listeJoueurs[i];
          if (!this.ls.statistique[cle]) {
            this.ls.statistique[cle] = {
              nbTourThreeman: 0,
              nbGorgee: 0,
              nbSec: 0,
            };
          }
        }
      }
    },
    nouvellePartie() {
      localStorage.removeItem("ls");
      window.location.href = "./";
    },
    fermerRegle() {
      for (let i = this.ls.regles.length - 1; i >= 0; i--) {
        if (!this.ls.regles[i]) {
          this.ls.regles.splice(i, 1);
        }
      }
      this.ls.regles.push(this.regleRedac);
      this.ls.statut = "chgmtJoueur";
      this.regleRedac = "";
      this.regleAffiche = false;
      this.lancerDesIntermediaire();
    },
    reglePredefini(index) {
      let rrr = this.reglesPredif[index];
      for (let i = this.ls.regles.length - 1; i >= 0; i--) {
        if (!this.ls.regles[i]) {
          this.ls.regles.splice(i, 1);
        }
      }
      this.ls.regles.push(this.reglesPredif[index]);
      this.reglesPredif.splice(index, 1);
      this.regleRedac = "";
      this.regleAffiche = false;

      if (rrr === "Co-Threeman") {
        this.ls.statut = "coThreeman";
        this.lancerDesIntermediaire();
      } else {
        this.ls.statut = "chgmtJoueur";
        this.lancerDesIntermediaire();
      }
    },
    suppRegle(a) {
      this.ls.regles.splice(a, 1);
      this.ls.statut = "chgmtJoueur";
      this.regleRedac = "";
      this.regleAffiche = false;
      this.lancerDesIntermediaire();
    },
    fermerStat() {
      this.statistique = false;
    },
    ouvrirStat() {
      this.statistique = true;
    },
    ouvrirChoix(message) {
      this.choixMessage = message;
      this.choix = true;
    },
    choixBoisson(a, b) {
      this.choix = false;
      if (Number.isInteger(b)) {
        if (this.ls.statut === "boireTheme") {
          this.ls.statut = "chgmtJoueur";
        } else {
          this.ls.statut = "jeu";
        }
        for (let i = 1; i <= b; i++) {
          this.boireGorgee(a);
        }
      } else if (b === "sec") {
        this.boireSec(a);
        this.ls.statut = "chgmtJoueur";
      } else if (b === "CoThreeman") {
        this.ls.positionCoThreeman = a;
        this.ls.nbthreeman = 2;
      }
    },
    enleverGorgee(joueur) {
      let sum = 0;

      if (this.listeJoueursDistri[joueur] !== 0) {
        this.listeJoueursDistri[joueur]--;
      }

      for (let i = 0; i < this.listeJoueursDistri.length; i++) {
        sum += this.listeJoueursDistri[i];
      }

      if (sum !== this.nbGorgeeDistri) {
        this.validerDistri = false;
      }
    },
    ajouterGorgee(joueur) {
      let sum = 0;
      for (let i = 0; i < this.listeJoueursDistri.length; i++) {
        sum += this.listeJoueursDistri[i];
      }

      if (
        //this.listeJoueursDistri[joueur] !== this.nbGorgeeDistri &&
        sum < this.nbGorgeeDistri
      ) {
        this.listeJoueursDistri[joueur]++;
      }

      sum = 0;
      for (let i = 0; i < this.listeJoueursDistri.length; i++) {
        sum += this.listeJoueursDistri[i];
      }

      if (sum === this.nbGorgeeDistri) {
        this.validerDistri = true;
      } else {
        this.validerDistri = false;
      }
    },
    ouvrirDistri() {
      this.distribution = true;
      for (let i = 0; i < this.ls.listeJoueurs.length; i++) {
        this.listeJoueursDistri[i] = 0;
      }
    },
    fermerDistri() {
      this.distribution = false;
      this.validerDistri = false;
      this.autreDes = false;

      if (this.ls.statut === "doubledoublesix") {
        this.ls.statut = "chgmtJoueur";
      } else {
        this.ls.statut = "jeu";
      }
      for (let i = 0; i < this.ls.listeJoueurs.length; i++) {
        for (let j = 0; j < this.listeJoueursDistri[i]; j++) {
          this.boireGorgee(i);
        }
      }
    },
    fermerAlerte() {
      if (this.ls.statut === "chgmtJoueur") {
        this.changementJoueurStep2();
        this.messages = ["", ""];
      }
      if (this.ls.statut !== "chgmtJoueurRecherche") {
        this.ls.statut = "jeu";
      } else {
        this.changementJoueurStep2();
        this.ls.statut = "recherche";
      }
      this.ls.combinaison = [];
      this.alerte = false;
    },
    afficherlisteJoueurs() {
      this.nbPlayer = this.ls.listeJoueurs.length;
      this.displayPlayerList = true;
    },
    definirJoueur() {
      this.nextPlayer =
        this.ls.currentPlayer + 1 === this.ls.listeJoueurs.length
          ? 0
          : this.ls.currentPlayer + 1;
      this.previousPlayer =
        this.ls.currentPlayer === 0
          ? this.ls.listeJoueurs.length - 1
          : this.ls.currentPlayer - 1;
    },
    lanceurAlerte(message, avecDes = false) {
      document.querySelector(".dice--1").style.transform = "rotate(0deg)";
      document.querySelector(".dice--2").style.transform = "rotate(0deg)";
      this.messageAlerte = message;
      this.desAlerte = avecDes;
      this.alerte = true;
    },

    precedentJoueur() {
      this.definirJoueur();
      console.log("Precedent : " + this.previousPlayer);
      this.ls.statistique[this.ls.listeJoueurs[this.previousPlayer]].nbGorgee++;
      if (this.dice1 === 3 || this.dice2 === 3) {
        if (this.ls.doubleTreeman) {
          this.messages = [
            "ThreeMan et Précédent",
            `${this.ls.listeJoueurs[this.previousPlayer]}  boit une gorgée et ${
              this.threeman
            } boit 2 gorgées`,
          ];
        } else {
          this.messages = [
            "ThreeMan et Précédent",
            `${this.ls.listeJoueurs[this.previousPlayer]} et ${
              this.threeman
            } boivent une gorgée chacun`,
          ];
        }

        this.threemanBoit();
      } else {
        this.messages = [
          "Précédent",
          `${this.ls.listeJoueurs[this.previousPlayer]} boit une gorgée`,
        ];
      }
    },
    threemanBoit() {
      this.ls.statistique[this.ls.listeJoueurs[this.ls.positionThreeman]]
        .nbGorgee++;
      if (this.ls.doubleTreeman) {
        this.messages = ["THREEMAN ", this.threeman + " boit deux gorgées"];
        this.ls.statistique[this.ls.listeJoueurs[this.ls.positionThreeman]]
          .nbGorgee++;
      }
    },
    boireSec(a) {
      this.ls.statistique[this.ls.listeJoueurs[a]].nbSec++;
    },
    boireGorgee(a) {
      this.ls.statistique[this.ls.listeJoueurs[a]].nbGorgee++;
    },
    lancerAutreDes(a) {
      if (a === 1) {
        this.diceExtra = this.dice1;
      } else if (a === 2) {
        this.diceExtra = this.dice2;
      }

      this.autreDes = true;

      setTimeout(() => {
        document.querySelector("img").style.transform = "rotate(720deg)";
      }, 40);

      setTimeout(() => {
        this.diceExtra = this.getRandomNumber(1, 6);

        if (this.ls.statut === "DoubleSix") {
          this.ls.statut = "chgmtJoueurs";
          //this.autreDes = false;
          if (this.diceExtra === 6) {
            this.messages = [
              "TOUT LE MONDE BOIT SEC",
              "et glou et glou et glou",
            ];
            for (let i = 0; i < this.ls.listeJoueurs.length; i++) {
              this.boireSec(i);
            }
          } else {
            this.messages = [
              `Tu bois ${this.diceExtra} gorgées et tu en distribues 6`,
              "",
            ];
            for (let i = 1; i <= this.diceExtra; i++) {
              this.boireGorgee(this.ls.currentPlayer);
            }
            this.nbGorgeeDistri = 6;
            this.ls.statut = "doubledoublesix";
          }
          //this.ouvrirDistri();
        }
      }, 1240);
    },

    lancerDes() {
      // if (
      //   document.querySelector(".dice--1").style.transform === "" ||
      //   document.querySelector(".dice--2").style.transform === ""
      // ) {
      //   document.querySelector(".dice--1").style.transform === "rotate(0deg)";
      //   document.querySelector(".dice--2").style.transform === "rotate(0deg)";
      // }

      if (
        document.querySelector(".dice--1").style.transform === "rotate(0deg)" ||
        document.querySelector(".dice--2").style.transform === "rotate(0deg)" ||
        document.querySelector(".dice--1").style.transform === "" ||
        document.querySelector(".dice--2").style.transform === ""
      ) {
        document.querySelector(".dice--1").style.transform = "rotate(720deg)";
        document.querySelector(".dice--2").style.transform = "rotate(-720deg)";
      } else {
        document.querySelector(".dice--1").style.transform = "rotate(0deg)";
        document.querySelector(".dice--2").style.transform = "rotate(0deg)";
      }
      setTimeout(() => {
        this.dice1 = this.getRandomNumber(1, 6);
        this.dice2 = this.getRandomNumber(1, 6);
        this.ls.lastcombi = [this.dice1, this.dice2];
        this.ls.combinaison.push([this.dice1, this.dice2].sort());
        this.actionApresLancer(this.dice1, this.dice2);
        this.verifCombi([this.dice1, this.dice2].sort());
      }, 1200);
    },
    verifCombi(arr) {
      let result = 0;

      for (let i = 0; i < this.ls.combinaison.length; i++) {
        if (
          this.ls.combinaison[i][0] === arr[0] &&
          this.ls.combinaison[i][1] === arr[1]
        ) {
          result++;
        }
      }

      if (result === 3) {
        this.messages = ["CA FAIT 3 FOIS !!!", "Tu bois sec !"];
        this.boireSec(this.ls.currentPlayer);
        this.changementJoueur();
      }
    },
    lancerDesTheme() {
      if (
        document.querySelector(".dice--1").style.transform === "rotate(0deg)" ||
        document.querySelector(".dice--2").style.transform === "rotate(0deg)" ||
        document.querySelector(".dice--1").style.transform === "" ||
        document.querySelector(".dice--2").style.transform === ""
      ) {
        document.querySelector(".dice--1").style.transform = "rotate(720deg)";
        document.querySelector(".dice--2").style.transform = "rotate(-720deg)";
      } else {
        document.querySelector(".dice--1").style.transform = "rotate(0deg)";
        document.querySelector(".dice--2").style.transform = "rotate(0deg)";
      }
      setTimeout(() => {
        this.dice1 = this.getRandomNumber(1, 6);
        this.dice2 = this.getRandomNumber(1, 6);
        this.messages = [
          "Thème",
          `Choisis un thème, celui qui perd boit ${
            this.dice1 + this.dice2
          } gorgées`,
        ];
        this.nbGorgeeChoix = this.dice1 + this.dice2;
        this.ls.statut = "boireTheme";
      }, 1200);
    },
    lancerQCVU(a) {
      let v = 0;
      if (this.dice1 === 2 || this.dice2 === 2) {
        v = 1;
      } else {
        v = 2;
      }

      if (a === 1) {
        if (
          document.querySelector(".dice--1").style.transform ===
            "rotate(0deg)" ||
          document.querySelector(".dice--1").style.transform === ""
        ) {
          document.querySelector(".dice--1").style.transform = "rotate(720deg)";
        } else {
          document.querySelector(".dice--1").style.transform = "rotate(0deg)";
        }
      } else if (a === 2) {
        if (
          document.querySelector(".dice--2").style.transform ===
            "rotate(0deg)" ||
          document.querySelector(".dice--2").style.transform === ""
        ) {
          document.querySelector(".dice--2").style.transform = "rotate(720deg)";
        } else {
          document.querySelector(".dice--2").style.transform = "rotate(0deg)";
        }
      }

      setTimeout(() => {
        if (a === 1) {
          this.dice1 = this.getRandomNumber(1, 6);
          if (this.dice1 === v) {
            this.messages = ["Distribue un sec", ""];
            this.nbGorgeeChoix = "sec";
            this.ls.statut = "SecQCVU";
          } else {
            this.messages = ["Tu bois 3 gorgées", ""];
            this.boireGorgee(this.ls.currentPlayer);
            this.boireGorgee(this.ls.currentPlayer);
            this.boireGorgee(this.ls.currentPlayer);
            this.ls.statut = "chgmtJoueur";
          }
        } else if (a === 2) {
          this.dice2 = this.getRandomNumber(1, 6);
          if (this.dice2 === v) {
            this.messages = ["Distribue un sec", ""];
            this.nbGorgeeChoix = "sec";
            this.ls.statut = "SecQCVU";
          } else {
            this.messages = ["Tu bois 3 gorgées", ""];
            this.boireGorgee(this.ls.currentPlayer);
            this.boireGorgee(this.ls.currentPlayer);
            this.boireGorgee(this.ls.currentPlayer);
            this.ls.statut = "chgmtJoueur";
          }
        }
      }, 1200);
    },
    lancerDesIntermediaire(a) {
      this.jeuDebute = true;
      if (this.ls.statut === "jeu") {
        this.autreDes = false;
        this.lancerDes();
      } else if (this.ls.statut === "DoubleSix") {
        this.lancerAutreDes(a);
      } else if (this.ls.statut === "recherche") {
        this.messages = ["Recherche du Threeman"];
        this.lancerDesRecherche();
      } else if (this.ls.statut === "coThreeman") {
        this.nbGorgeeChoix = "CoThreeman";
        this.ouvrirChoix("Qui est le co-Threeman ?");
      } else if (this.ls.statut === "theme") {
        this.lancerDesTheme();
      } else if (this.ls.statut === "regle") {
        this.regleAffiche = true;
        if (this.ls.regles[0] === "Aucune règle pour le moment") {
          this.ls.regles[0] = null;
        }
      } else if (this.ls.statut === "QCVU") {
        this.lancerQCVU(a);
      } else if (
        this.ls.statut === "doubledouble" ||
        this.ls.statut === "doubledoublesix"
      ) {
        this.ouvrirDistri();
      } else if (this.ls.statut === "boireTheme") {
        this.ouvrirChoix("Qui a perdu le thème ?");
      } else if (this.ls.statut === "SecQCVU") {
        this.ouvrirChoix("Le sec il est pour qui ?");
      } else if (this.ls.statut === "rapidite") {
        this.ouvrirChoix(
          "Qui était le moins rapide ?<p style='color: white'>2 gorgées pour le retard</p>"
        );
      } else if (this.ls.statut === "chgmtJoueur") {
        if (this.ls.currentPlayer === this.ls.positionThreeman) {
          this.ls.statut = "chgmtJoueurRecherche";
          this.ls.manche++;
          let v =
            this.ls.currentPlayer + 1 === this.ls.listeJoueurs.length
              ? 0
              : this.ls.currentPlayer + 1;
          this.lanceurAlerte(
            `Fin de la manche ! On repart en recherche ! A ${this.ls.listeJoueurs[v]}`
          );
        } else {
          let v =
            this.ls.currentPlayer + 1 === this.ls.listeJoueurs.length
              ? 0
              : this.ls.currentPlayer + 1;
          this.lanceurAlerte(`C'est à ${this.ls.listeJoueurs[v]} de jouer`);
        }
      }
    },
    lancerDesRecherche() {
      if (
        document.querySelector(".dice--1").style.transform === "rotate(0deg)"
      ) {
        document.querySelector(".dice--1").style.transform = "rotate(720deg)";
        document.querySelector(".dice--2").style.transform = "rotate(-720deg)";
      } else {
        document.querySelector(".dice--1").style.transform = "rotate(0deg)";
        document.querySelector(".dice--2").style.transform = "rotate(0deg)";
      }

      setTimeout(() => {
        this.dice1 = this.getRandomNumber(1, 6);
        this.dice2 = this.getRandomNumber(1, 6);
        this.ls.lastcombi = [this.dice1, this.dice2];
        if (
          this.dice1 === 3 ||
          this.dice2 === 3 ||
          this.dice1 + this.dice2 === 3
        ) {
          this.ls.statut = "jeu";

          if (this.dice1 + this.dice2 === 6) {
            this.ls.statistique[this.ls.listeJoueurs[this.ls.currentPlayer]]
              .nbTourThreeman++;
            this.ls.statistique[this.ls.listeJoueurs[this.ls.currentPlayer]]
              .nbTourThreeman++;
            this.lanceurAlerte(
              this.ls.listeJoueurs[this.ls.currentPlayer] +
                " devient double threeman ! Pas une mais 2 gorgées de bienvenue !",
              true
            );
            this.threemanBoit();
            this.threemanBoit();
            this.ls.doubleTreeman = true;
          } else {
            this.ls.statistique[this.ls.listeJoueurs[this.ls.currentPlayer]]
              .nbTourThreeman++;
            this.lanceurAlerte(
              this.ls.listeJoueurs[this.ls.currentPlayer] +
                " devient le nouveau threeman ! Une gorgée de bienvenue !",
              true
            );
            this.ls.doubleTreeman = false;
            this.threemanBoit();
          }
          this.threeman = this.ls.listeJoueurs[this.ls.currentPlayer];
          this.ls.positionThreeman = this.ls.currentPlayer;
          this.messages = [];

          this.changementJoueur();
        } else {
          this.changementJoueur();
        }
      }, 1200);
    },
    changementJoueur() {
      if (this.ls.statut !== "recherche") {
        this.ls.statut = "chgmtJoueur";
      } else {
        this.changementJoueurStep2();
      }
      // if (this.ls.currentPlayer + 1 === this.ls.listeJoueurs.length) {
      //   this.ls.currentPlayer = 0;
      // } else {
      //   this.ls.currentPlayer++;
      // }
      this.ls.combinaison = [];
      this.definirJoueur();
    },
    changementJoueurStep2() {
      if (this.ls.currentPlayer + 1 === this.ls.listeJoueurs.length) {
        this.ls.currentPlayer = 0;
      } else {
        this.ls.currentPlayer++;
      }
    },
    actionApresLancer(d1, d2) {
      if (d1 === 1 && d2 === 1) {
        this.messages = ["AUTOKILL!", "Tu bois sec !"];
        this.boireSec(this.ls.currentPlayer);
        this.changementJoueur();
      } else if (d1 === 1 && d2 === 2) {
        this.messages = ["THREEMAN ", this.threeman + " boit une gorgée"];
        this.threemanBoit();
      } else if (d1 === 1 && d2 === 3) {
        this.messages = ["THREEMAN ", this.threeman + " boit une gorgée"];
        this.threemanBoit();
      } else if (d1 === 1 && d2 === 4) {
        this.messages = ["421", "Relance un dés et essaye de faire 2"];
        this.ls.statut = "QCVU";
      } else if (d1 === 1 && d2 === 5) {
        this.messages = ["51 ! Pastis mmmmmh - ", "Tout le monde boit"];
        for (let i = 0; i < this.ls.listeJoueurs.length; i++) {
          this.boireGorgee(i);
        }
        this.changementJoueur();
      } else if (d1 === 1 && d2 === 6) {
        this.messages = ["Un doigt devant ", "Le dernier  boit deux gorgées"];
        this.nbGorgeeChoix = 2;
        this.ls.statut = "rapidite";
      } else if (d1 === 2 && d2 === 1) {
        this.messages = ["THREEMAN ", this.threeman + " boit une gorgée"];
        this.threemanBoit();
      } else if (d1 === 2 && d2 === 2) {
        this.messages = ["Distribue 2 gorgées", ""];
        this.nbGorgeeDistri = 2;
        this.ls.statut = "doubledouble";
      } else if (d1 === 2 && d2 === 3) {
        this.messages = ["THREEMAN ", this.threeman + " boit une gorgée"];
        this.threemanBoit();
      } else if (d1 === 2 && d2 === 4) {
        this.messages = ["421", "Relance un dés et essaye de faire 1"];
        this.ls.statut = "QCVU";
      } else if (d1 === 2 && d2 === 5) {
        this.precedentJoueur();
      } else if (d1 === 2 && d2 === 6) {
        this.messages = ["Deux doigts devant", "Le dernier boit deux gorgées"];
        this.nbGorgeeChoix = 2;
        this.ls.statut = "rapidite";
      } else if (d1 === 3 && d2 === 1) {
        this.messages = ["THREEMAN ", this.threeman + " boit une gorgée"];
        this.threemanBoit();
      } else if (d1 === 3 && d2 === 2) {
        this.messages = ["THREEMAN ", this.threeman + " boit une gorgée"];
        this.threemanBoit();
      } else if (d1 === 3 && d2 === 3) {
        if (this.ls.doubleTreeman) {
          this.messages = [
            "DOUBLE THREEMAN",
            this.threeman +
              " est double Threeman, double x double = " +
              this.threeman +
              " boit deux gorgées",
          ];
        } else {
          this.messages = [
            "DOUBLE THREEMAN",
            this.threeman + " boit deux gorgées",
          ];
        }
        this.threemanBoit();
        this.threemanBoit();
      } else if (d1 === 3 && d2 === 4) {
        this.precedentJoueur();
      } else if (d1 === 3 && d2 === 5) {
        this.messages = ["THREEMAN ", this.threeman + " boit une gorgée"];
        this.threemanBoit();
      } else if (d1 === 3 && d2 === 6) {
        this.messages = ["Le poing devant ", "Le dernier  boit deux gorgées"];
        this.nbGorgeeChoix = 2;
        this.ls.statut = "rapidite";
      } else if (d1 === 4 && d2 === 1) {
        this.messages = ["421", "Relance un dés et essaye de faire 2"];
        this.ls.statut = "QCVU";
      } else if (d1 === 4 && d2 === 2) {
        this.messages = ["421", "Relance un dés et essaye de faire 1"];
        this.ls.statut = "QCVU";
      } else if (d1 === 4 && d2 === 3) {
        this.precedentJoueur();
        this.threemanBoit();
      } else if (d1 === 4 && d2 === 4) {
        this.messages = ["Distribue 4 gorgées", ""];
        this.nbGorgeeDistri = 4;
        this.ls.statut = "doubledouble";
      } else if (d1 === 4 && d2 === 5) {
        this.messages = [
          "Thème",
          "Relance les dés pour définir le nombre de gorgées en jeu",
        ];
        this.ls.statut = "theme";
      } else if (d1 === 4 && d2 === 6) {
        this.messages = ["Règle", "Définis une nouvelle règle"];
        this.ls.statut = "regle";
      } else if (d1 === 5 && d2 === 1) {
        this.messages = ["51 ! Pastis mmmmmh", "Tout le monde boit"];
        this.changementJoueur();
      } else if (d1 === 5 && d2 === 2) {
        this.precedentJoueur();
      } else if (d1 === 5 && d2 === 3) {
        this.messages = ["THREEMAN ", this.threeman + " boit une gorgée"];
        this.threemanBoit();
      } else if (d1 === 5 && d2 === 4) {
        this.messages = [
          "THEME",
          "Relance les dés pour définir le nombre de gorgées en jeu",
        ];
        this.ls.statut = "theme";
      } else if (d1 === 5 && d2 === 5) {
        this.messages = ["Distribue 5 gorgées", ""];
        this.nbGorgeeDistri = 5;
        this.ls.statut = "doubledouble";
      } else if (d1 === 5 && d2 === 6) {
        this.definirJoueur();
        this.ls.statistique[this.ls.listeJoueurs[this.nextPlayer]].nbGorgee++;
        this.messages = [
          "Suivant",
          `${this.ls.listeJoueurs[this.nextPlayer]} boit une gorgée`,
        ];
      } else if (d1 === 6 && d2 === 1) {
        this.messages = ["Un doigt devant", "Le dernier  boit deux gorgées"];
        this.nbGorgeeChoix = 2;
        this.ls.statut = "rapidite";
      } else if (d1 === 6 && d2 === 2) {
        this.messages = ["Deux doigts devant", "Le dernier  boit deux gorgées"];
        this.nbGorgeeChoix = 2;
        this.ls.statut = "rapidite";
      } else if (d1 === 6 && d2 === 3) {
        this.messages = ["Un poing devant", "Le dernier  boit deux gorgées"];
        this.nbGorgeeChoix = 2;
        this.ls.statut = "rapidite";
      } else if (d1 === 6 && d2 === 4) {
        this.messages = ["Règle", "Définis une nouvelle règle"];
        this.ls.statut = "regle";
      } else if (d1 === 6 && d2 === 5) {
        this.definirJoueur();
        this.ls.statistique[this.ls.listeJoueurs[this.nextPlayer]].nbGorgee++;
        this.messages = [
          "Suivant",
          `${this.ls.listeJoueurs[this.nextPlayer]} boit une gorgée`,
        ];
      } else if (d1 === 6 && d2 === 6) {
        this.messages = [
          "Relance un dés",
          "Si tu fais six, tout le monde boit sec, sinon boit le nombre de gorgées et distribue en 6",
        ];
        this.changementJoueur();
        this.ls.statut = "DoubleSix";
      } else {
        this.messages = ["Relance 1 dé", "AAAAAAEEEEE"];
      }
    },
  },
};
</script>

<style lang="scss" src="./GameView.scss" scoped></style>
